// These objects have to be initialized in the browser-only code
// because Amplitude SDK we use under the hood at the moment requires the windows object.
let analyticsWebTracker = null
let analyticsUrlParams = null
let AnalyticsLocalExperiment = null
let analyticsClient = null

export const webTracker = () => {
  if (analyticsWebTracker == null) {
    throw new Error('Analytics is not initialized')
  }
  return analyticsWebTracker
}

export const urlParams = () => {
  if (analyticsUrlParams == null) {
    throw new Error('Analytics is not initialized')
  }
  return analyticsUrlParams
}

export const setAnalyticsObjects = (
  webTracker,
  urlParams,
  client,
  LocalExperiment
) => {
  analyticsWebTracker = webTracker
  analyticsUrlParams = urlParams
  analyticsClient = client
  AnalyticsLocalExperiment = LocalExperiment
}
