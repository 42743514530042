require('@fortawesome/fontawesome/styles.css')
const ReactGA = require('react-ga')

const { webTracker } = require('./src/utils/analytics')
const { setAnalyticsParams } = require('./src/utils/analytics-browser')

exports.onClientEntry = () => {
  // The main analytics initialization.
  setAnalyticsParams({
    endpoint: 'data.balena-cloud.com',
  })

  ReactGA.initialize('UA-45671959-1')
}

exports.onRouteUpdate = () => {
  webTracker().trackPageView()

  ReactGA.pageview(window.location.pathname + window.location.search)
}
