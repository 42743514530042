import {
  AnalyticsUrlParams,
  createMarketingClient,
  createNoopClient,
  createWebTracker,
  LocalExperiment,
} from 'analytics-client'
import { version } from '../../package.json'
import { setAnalyticsObjects } from './analytics'

const defaultAnalyticsParams = {
  projectName: 'balena-main',
  componentName: 'Blog',
  componentVersion: version,
}

export const setAnalyticsParams = (params) => {
  const urlParams = new AnalyticsUrlParams()
  const newQuery = urlParams.consumeUrlParameters(window.location.search)
  if (newQuery != null) {
    const newUrl = window.location.pathname + (!!newQuery ? `?${newQuery}` : '')
    window.history.replaceState(null, '', newUrl)
  }

  const client = urlParams.isOptOutRequested()
    ? createNoopClient()
    : createMarketingClient({
        ...defaultAnalyticsParams,
        ...params,
        deviceId: urlParams.getPassedDeviceId(),
      })
  urlParams.setClient(client)

  const webClient = createWebTracker(client, 'Blog')
  setAnalyticsObjects(webClient, urlParams, client, LocalExperiment)
}
